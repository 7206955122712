var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"real-name-authentication-box"},[(!_vm.hasAuthentication)?_c('div',{staticClass:"real-name-authentication-before"},[_vm._m(0),_vm._m(1),_vm._m(2)]):_vm._e(),(_vm.hasAuthentication)?_c('div',{staticClass:"real-name-authentication-after"},[_vm._m(3),_vm._m(4),_vm._m(5)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"real-name-authentication-tips"},[_c('img',{staticClass:"real-name-authentication-not-done-icon",attrs:{"src":require("../../../../public/images/home/my_center_not_done_icon.png"),"alt":"实名认证"}}),_c('p',{staticClass:"real-name-authentication-not-done-text"},[_vm._v("请进行实名认证，认证通过后方可进行商品交易！根据相关规定，严禁未成年人购买虚拟商品！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"real-name-authentication-profile-box"},[_c('ul',[_c('li',[_c('img',{staticClass:"real-name-authentication-profile-pic",attrs:{"src":require("../../../../public//images/home/my_center_authentication_template_a.png"),"alt":"证件正面"}}),_c('p',{staticClass:"real-name-authentication-profile-text"},[_vm._v("上传身份证人像面")])]),_c('li',[_c('img',{staticClass:"real-name-authentication-profile-pic",attrs:{"src":require("../../../../public//images/home/my_center_authentication_template_b.png"),"alt":"证件反面"}}),_c('p',{staticClass:"real-name-authentication-profile-text"},[_vm._v("上传身份证国徽面")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"real-name-authentication-profile-commit-btn"},[_c('p',{staticClass:"real-name-authentication-profile-commit-btn-text"},[_vm._v("提交")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"real-name-authentication-after-tips"},[_c('img',{staticClass:"real-name-authentication-has-done-icon",attrs:{"src":require("../../../../public/images/home/my_center_has_done_icon.png"),"alt":"实名认证"}}),_c('p',{staticClass:"real-name-authentication-has-done-text"},[_vm._v("您已完成实名认证！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"real-name-authentication-after-profile-box"},[_c('ul',[_c('li',[_c('img',{staticClass:"real-name-authentication-after-profile-pic",attrs:{"src":"https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/bdf04ecfe3394776bfd13ff0e856e7ab_mergeImage.png","alt":"证件正面"}})]),_c('li',[_c('img',{staticClass:"real-name-authentication-after-profile-pic",attrs:{"src":"https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/3a88bb6efdaf4cf19e3dc50c1afcac6d_mergeImage.png","alt":"证件反面"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"real-name-authentication-after-commit-btn"},[_c('p',{staticClass:"real-name-authentication-after-commit-btn-text"},[_vm._v("重新认证")])])
}]

export { render, staticRenderFns }
<template>
  <div class="real-name-authentication-box">
    <div v-if="!hasAuthentication" class="real-name-authentication-before">
      <div class="real-name-authentication-tips">
        <img  class="real-name-authentication-not-done-icon" src="../../../../public/images/home/my_center_not_done_icon.png" alt="实名认证">
        <p class="real-name-authentication-not-done-text">请进行实名认证，认证通过后方可进行商品交易！根据相关规定，严禁未成年人购买虚拟商品！</p>
      </div>
      <div class="real-name-authentication-profile-box">
        <ul>
          <li>
            <img class="real-name-authentication-profile-pic" src="../../../../public//images/home/my_center_authentication_template_a.png" alt="证件正面" >
            <p class="real-name-authentication-profile-text">上传身份证人像面</p>
          </li>
          <li>
            <img class="real-name-authentication-profile-pic" src="../../../../public//images/home/my_center_authentication_template_b.png" alt="证件反面" >
            <p class="real-name-authentication-profile-text">上传身份证国徽面</p>
          </li>
        </ul>
      </div>
      <div class="real-name-authentication-profile-commit-btn">
        <p class="real-name-authentication-profile-commit-btn-text">提交</p>
      </div>
    </div>
    <div v-if="hasAuthentication" class="real-name-authentication-after">
      <div class="real-name-authentication-after-tips">
        <img  class="real-name-authentication-has-done-icon" src="../../../../public/images/home/my_center_has_done_icon.png" alt="实名认证">
        <p class="real-name-authentication-has-done-text">您已完成实名认证！</p>
      </div>
      <div class="real-name-authentication-after-profile-box">
        <ul>
          <li>
            <img class="real-name-authentication-after-profile-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/bdf04ecfe3394776bfd13ff0e856e7ab_mergeImage.png" alt="证件正面" >
          </li>
          <li>
            <img class="real-name-authentication-after-profile-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/3a88bb6efdaf4cf19e3dc50c1afcac6d_mergeImage.png" alt="证件反面" >
          </li>
        </ul>
      </div>
      <div class="real-name-authentication-after-commit-btn">
        <p class="real-name-authentication-after-commit-btn-text">重新认证</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name:"MyAccountSettingAuthentication",
  data(){
    return{
      hasAuthentication:false
    }
  }
}
</script>

<style lang="less" scoped>
.real-name-authentication-box{
    background: #FFFFFF;
    width: 1200px;
    margin-top: 20px;

    .real-name-authentication-before{
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .real-name-authentication-tips{
        width: 546px;
        height: 36px;
        background: #FFF0F1;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;
        .real-name-authentication-not-done-icon{
          width: 12px;
          height: 12px;
        }
        .real-name-authentication-not-done-text{
          margin-left: 10px;
          height: 16px;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #F02233;
          line-height: 16px;
          text-align: left;
          font-style: normal;
        }
      }
      .real-name-authentication-profile-box{
        margin-top: 20px;
        width: 1200px;
        ul{
          width: 1200px;
          list-style-type: none;
          padding: 0;
          display: flex;
          justify-content: center;
          li{
            list-style: none;
            width: 335px;
            height: 211px;
            background: #FFFFFF;
            border-radius: 10px;
            border: 1px solid #EBEBEB;
            margin-left: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .real-name-authentication-profile-pic{
              width: 237px;
              height: 145px;
            }
            .real-name-authentication-profile-text{
              margin-top: 9px;
              height: 20px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #1A1A1A;
              line-height: 20px;
              text-align: right;
              font-style: normal;
            }
          }
        }
      }
      .real-name-authentication-profile-commit-btn{
        margin-top: 50px;
        width: 200px;
        height: 46px;
        background: #F02233;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
        .real-name-authentication-profile-commit-btn-text{
          height: 14px;
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: bold;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 14px;
          text-align: right;
          font-style: normal;
        }
      }
    }

    .real-name-authentication-after{
      width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      .real-name-authentication-after-tips{
        display: flex;
        width: 150px;
        height: 36px;
        background: #ECFFF5;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
        .real-name-authentication-has-done-icon{
          width: 12px;
          height: 12px;
        }
        .real-name-authentication-has-done-text{
          margin-left: 10px;
          height: 16px;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #2CA767;
          line-height: 16px;
          text-align: left;
          font-style: normal;
        }
      }
      .real-name-authentication-after-profile-box{
        margin-top: 20px;
        width: 1200px;
        ul{
          width: 1200px;
          list-style-type: none;
          padding: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          li{
            margin-left: 40px;
            list-style: none;
            width: 335px;
            height: 211px;
            background: #FFFFFF;
            border-radius: 10px;
            border: 1px solid #EBEBEB;
            .real-name-authentication-after-profile-pic{
              width: 335px;
              height: 211px;
            }
          }
        }
      }
      .real-name-authentication-after-commit-btn{
        margin-top: 50px;
        width: 200px;
        height: 46px;
        background: #FFF7F7;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
        .real-name-authentication-after-commit-btn-text{
          height: 14px;
          font-family: MicrosoftYaHei, MicrosoftYaHei;
          font-weight: bold;
          font-size: 14px;
          color: #FF707B;
          line-height: 14px;
          text-align: right;
          font-style: normal;
        }
      }


    }

}
</style>